<template>
  <v-container fluid>
    <div v-if="designFilePackages.length > 0">
      <base-page-title
        >Design Package: Fixture Design {{ partNumber }} - Project
        {{ projectName }}</base-page-title
      >
      <v-toolbar v-if="stateDescription" dense flat>
        <v-row>
          <v-col cols="12" md="8" class="d-flex align-center">
            <span>
              Fixture Design State:
              {{ fixtureStateName }}
              - {{ stateDescription }}
            </span>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              block
              color="secondary"
              @click="gotoLink('/fulfillment/project/' + $route.params.id)"
              >Fulfillment View</v-btn
            ></v-col
          >
          <v-col cols="12" md="2">
            <design-file-package-modal
              :renderList="renderList"
              @saveDesignFile="onSaveNewDFP"
            />
          </v-col>
        </v-row>
      </v-toolbar>
      <v-toolbar dense flat>
        <v-row>
          <v-col cols="12">
            <v-btn text color="secondary" @click="showTablesHandler">
              {{ showAllTables ? "Hide All Tables" : "Show All Tables" }}</v-btn
            >
          </v-col>
        </v-row>
      </v-toolbar>
    </div>
    <div v-if="designFilePackages.length === 0">
      <design-file-package-modal
        v-if="isAdmin"
        :renderList="renderList"
        @saveDesignFile="onSaveNewDFP"
      />
    </div>
    <div v-else>
      <v-sheet
        v-for="(designFilePackage, index) in designFilePackages"
        :key="index"
        outlined
        class="mb-5 pa-1"
        elevation="6"
      >
        <fixture-design-block
          ref="designBlock"
          :isAdmin="isAdmin"
          :designFilePackage="designFilePackage"
          :selectedProject="selectedProject"
          :activeId="activeId"
          :isDFPView="true"
        />
      </v-sheet>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DesignPackagePage",
  components: {
    FixtureDesignBlock: () => import("../components/FixtureDesignBlock.vue"),
    DesignFilePackageModal: () => import("../components/DesignFilePackageModal.vue"),
  },
  data() {
    return {
      showAllTables: true,
    };
  },
  computed: {
    ...mapGetters({
      fixtureDesign: "fixturedesign/fixtureDesign",
      selectedProject: "projects/selProject",
      fdState: "fixturedesign/fdState",
      fdStateLongList: "ui/fdStateLongList",
      renderList: "dutconfigs/renderSettings",
    }),
    projectName() {
      return this.selectedProject.project_name;
    },
    fixtureStateName() {
      const result = this.fdState.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : "";
    },
    partNumber() {
      return this.fixtureDesign.part ? this.fixtureDesign.part.number : "";
    },
    partDescription() {
      return this.fixtureDesign.part ? this.fixtureDesign.part.description : "";
    },
    isAdmin() {
      return this.$store.state.auth.user.is_staff;
    },
    userId() {
      return this.$store.state.auth.user.pk;
    },
    isInitialState() {
      return this.fixtureDesign.state === 1;
    },
    stateDescription() {
      const result = this.fdStateLongList.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : null;
    },
    designFilePackages() {
      return [...this.fixtureDesign.design_file_packages].sort((a, b) => {
        if (a.active === b.active) {
          return b.version - a.version;
        }
        return a.active < b.active ? 1 : -1;
      });
    },
    activeId() {
      const record = this.fixtureDesign.design_file_packages.find(
        (element) => element.active
      );
      if (record) {
        return record.id;
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchFixtureDesign: "fixturedesign/getFixtureDesign",
      saveDesignFilePackage: "fixturedesign/postDesignFilePackage",
      initSelectedProject: "projects/clearProject",
      toggleLoading: "ui/loading",
    }),
    async onSaveNewDFP(payload) {
      let inputData = new FormData();
      inputData.append("user", this.userId);
      inputData.append("project", this.$route.params.id);
      inputData.append("fixture_design", this.fixtureDesign.pk);
      inputData.append("version", payload.version);
      if (payload.notes) {
        inputData.append("notes", payload.notes);
      }
      if (payload.pressurePlate) {
        inputData.append("pressure_plate_revision", payload.pressurePlate);
      }
      if (payload.pressurePlateAssembly) {
        inputData.append(
          "pressure_plate_assembly_revision",
          payload.pressurePlateAssembly
        );
      }
      if (payload.movingPlate) {
        inputData.append("moving_plate_revision", payload.movingPlate);
      }
      if (payload.movingPlateAssembly) {
        inputData.append(
          "moving_plate_assembly_revision",
          payload.movingPlateAssembly
        );
      }
      if (payload.probePlate) {
        inputData.append("probe_plate_revision", payload.probePlate);
      }
      if (payload.probePlateAssembly) {
        inputData.append(
          "probe_plate_assembly_revision",
          payload.probePlateAssembly
        );
      }
      if (payload.cartridgeAssembly) {
        inputData.append(
          "cartridge_assembly_revision",
          payload.cartridgeAssembly
        );
      }
      if (payload.fixtureAssembly) {
        inputData.append("fixture_assembly_revision", payload.fixtureAssembly);
      }
      if (payload.renderFile) {
        inputData.append("render_file_revision", payload.renderFile);
      }
      if (payload.renderSettings) {
        inputData.append("render_setting", payload.renderSettings);
      }
      try {
        await this.saveDesignFilePackage(inputData);
        this.toggleLoading("Refreshing data");
        await this.fetchFixtureDesign({
          params: { project_id: this.$route.params.id },
        });
        this.toggleLoading();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    gotoLink(link) {
      this.$router.push(link);
    },
    showTablesHandler() {
      this.showAllTables = !this.showAllTables;
      this.$refs.designBlock.forEach((element) => {
        element.showTable(this.showAllTables);
      });
    }
  },
  async mounted() {
    if (this.fixtureDesign.design_file_packages && this.fixtureDesign.design_file_packages.length === 0) {
      let mountedData = new FormData();
      mountedData.append("user", this.userId);
      mountedData.append("project", this.$route.params.id);
      mountedData.append("fixture_design", this.fixtureDesign.pk);
      mountedData.append("version", 1);
      mountedData.append("active", true);
      try {
        await this.saveDesignFilePackage(mountedData);
        this.toggleLoading("Refreshing data");
        await this.fetchFixtureDesign({
          params: { project_id: this.$route.params.id },
        });
        this.toggleLoading();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    } else if ((this.fixtureDesign.design_file_packages.filter((element) => element.active)).length === 0) {
      this.$store.commit(
        "ui/SNACK_BAR",
        "Select the DFP with the highest version, and set it to active, and continue."
      );
    } else {
      if ((this.fixtureDesign.design_file_packages.filter((element) => element.active)).length > 1) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "There are multiple Active Design File Packages, have an admin look into this."
        );
      }
    }
  },
  beforeDestroy() {
    this.initSelectedProject();
  },
};
</script>
